import React from 'react'
import { PageContainer, CenteredContent, NavigationMenu, NavigationMenuItem } from './styled'

import H2 from '@components/core/headings/h2'
import Paragraph from '@components/core/paragraph'
import TextLink from '@components/core/text-link'

export default function NotFoundContent() {
  return (
    <PageContainer>
      <CenteredContent>
        <H2 as="h1">404 | Stránka nenalezena</H2>
        <Paragraph>
          Omlouváme se, ale Vámi zadaná adresa webové stránky neexistuje. Prosím, zkuste odkazy
          níže:
        </Paragraph>
        <nav>
          <NavigationMenu>
            <NavigationMenuItem>
              <TextLink to="/">Domů</TextLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <TextLink to="/cenik-sluzeb">Ceník služeb</TextLink>
            </NavigationMenuItem>
          </NavigationMenu>
        </nav>
      </CenteredContent>
    </PageContainer>
  )
}
