import styled from 'styled-components'

const PageContainer = styled.main`
  min-height: 100vh;
  display: grid;
  place-items: center;
`

const CenteredContent = styled.div`
  text-align: center;
`

const NavigationMenu = styled.ul`
  margin-top: 1rem;
`

const NavigationMenuItem = styled.li`
  padding: 0.5rem;
`

export { PageContainer, CenteredContent, NavigationMenu, NavigationMenuItem }
