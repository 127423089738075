import React from 'react'

import SEO from '@components/core/seo'
import NotFoundContent from '@components/pages/404/NotFoundContent'

export default function NotFoundPage() {
  return (
    <>
      <SEO title="Stránka nenalezena" />

      <NotFoundContent />
    </>
  )
}
